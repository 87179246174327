import { useState, useEffect } from "react"

export const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(true)

  useEffect(() => {
    const checkIsMobile = () => window.innerWidth < 768

    const windowResized = () => {
      setIsMobile(checkIsMobile())
    }

    window.addEventListener("resize", windowResized)

    setIsMobile(checkIsMobile())

    return () => {
      window.removeEventListener("resize", windowResized)
    }
  }, [])

  return isMobile
}
