// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-directors-tsx": () => import("./../../../src/pages/directors.tsx" /* webpackChunkName: "component---src-pages-directors-tsx" */),
  "component---src-pages-featured-tsx": () => import("./../../../src/pages/featured.tsx" /* webpackChunkName: "component---src-pages-featured-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-publishing-tsx": () => import("./../../../src/pages/publishing.tsx" /* webpackChunkName: "component---src-pages-publishing-tsx" */),
  "component---src-pages-screenwriters-tsx": () => import("./../../../src/pages/screenwriters.tsx" /* webpackChunkName: "component---src-pages-screenwriters-tsx" */)
}

