import React from "react"
import { useTranslation } from "react-i18next"

const languageContext = React.createContext({})

type Props = {
  lang: string
}

export const LanguageContextProvider: React.FC<Props> = ({
  lang,
  children,
}) => {
  const { i18n } = useTranslation()

  if (lang && i18n.language !== lang) {
    i18n.changeLanguage(lang)
  }

  return (
    <languageContext.Provider value={languageContext}>
      {children}
    </languageContext.Provider>
  )
}

export const uselanguageContext = () => React.useContext(languageContext)
