import frUrls from "../locales/fr/urls.json"
import enUrls from "../locales/en/urls.json"

const urls = {
  fr: frUrls,
  en: enUrls,
}

export const getTranslatedUrl = (
  originalPath: string,
  lang: keyof typeof urls
): string => {
  if (urls?.[lang] && originalPath in urls[lang]) {
    return urls[lang][originalPath as keyof typeof frUrls | keyof typeof enUrls]
  }

  return `/${lang}${originalPath}`
}
