import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import enTranslations from "./locales/en/translations.json"
import frTranslations from "./locales/fr/translations.json"

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    detection: {
      order: [
        "htmlTag",
        "path",
        "subdomain",
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",
        "navigator",
      ],
    },
    fallbackLng: "en",
    resources: { ...enTranslations, ...frTranslations },
    debug: process.env.NODE_ENV === "development",

    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    /*
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    }
    */
  })

export default i18n
