import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import Logo from "./Logo"

// Hover lotties
import AboutHoverLottieData from "../../../static/lotties/about-hover.json"
import ContactHoverLottieData from "../../../static/lotties/contact-hover.json"
import GreenLineHoverLottieData from "../../../static/lotties/green-line.json"
import PublishingHoverLottieData from "../../../static/lotties/publishing-hover.json"

import { getTranslatedUrl } from "../../utils/urls"

import DelayLink from "../DelayLink"

const HeaderWrapper = styled.header<{ show?: boolean }>`
  top: 0;
  left: 0;
  width: 100%;
  position: fixed;
  padding: 20px 10px;
  z-index: 300;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity ease 300ms, background-color ease 300ms;
  background-color: transparent;
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? "all" : "none")};

  &.active {
    background-color: white;
  }

  @media (min-width: 992px) {
    padding: 20px;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const HeaderLinks = styled.div`
  display: flex;
  align-items: center;
`

const Link = styled(DelayLink)`
  text-transform: uppercase;
  font-family: "Avenir LT Std";
  font-size: 9pt;
  letter-spacing: 0.4em;
  margin: 0 10px;

  @media (min-width: 992px) {
    letter-spacing: 0.4em;
    margin: 0 20px;
  }
`

const IGLink = styled.a`
  margin: 0 20px;
  height: 25px;
`

const ContactLink = styled(Link)<{ show?: boolean }>`
  position: fixed;
  font-family: "Minion Pro";
  font-weight: 500;
  top: 50%;
  right: 0%;
  transform: rotate(-90deg) translate(50%, -25px);
  transform-origin: right top;
  z-index: 300;
  transition: opacity ease 300ms;
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? "all" : "none")};
`

type Props = {
  show?: boolean
}

const Header = ({ show }: Props): JSX.Element => {
  const { t, i18n } = useTranslation()
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  const currentLanguage = i18n.language as "fr" | "en"

  useEffect(() => {
    const onSmoothScroll = (e: any) => {
      if (e.detail.current.y > 100) {
        wrapperRef.current?.classList.add("active")
      } else {
        wrapperRef.current?.classList.remove("active")
      }
    }
    window.addEventListener("smoothscroll", onSmoothScroll)

    return () => {
      window.removeEventListener("smoothscroll", onSmoothScroll)
    }
  }, [])

  return (
    <>
      <HeaderWrapper show={show} ref={wrapperRef}>
        <HeaderContainer>
          <HeaderLinks>
            <Link
              to={getTranslatedUrl("/directors/", currentLanguage)}
              lottieData={GreenLineHoverLottieData}
              lottieStyles={{ offsetY: 15 }}
            >
              {t("directors")}
            </Link>
            <Link
              to={getTranslatedUrl("/screenwriters/", currentLanguage)}
              lottieData={GreenLineHoverLottieData}
              lottieStyles={{ offsetY: 15 }}
            >
              {t("screenwriters")}
            </Link>
          </HeaderLinks>

          <HeaderLinks>
            <Link
              to={getTranslatedUrl("/publishing/", currentLanguage)}
              lottieData={PublishingHoverLottieData}
              lottieStyles={{ offsetX: -15 }}
            >
              {t("publishing")}
            </Link>
            <Link
              to={getTranslatedUrl("/about/", currentLanguage)}
              lottieData={AboutHoverLottieData}
              lottieStyles={{
                height: 30,
                width: 130,
                offsetX: -32,
                offsetY: -10,
              }}
            >
              {t("about")}
            </Link>

            <IGLink
              href="https://www.instagram.com/filmtalents/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/instagram-icon.svg"
                width={20}
                height={20}
                alt="Film Talents - Instagram"
              />
            </IGLink>
          </HeaderLinks>
        </HeaderContainer>

        <Logo />
      </HeaderWrapper>
      <ContactLink
        show={show}
        to={getTranslatedUrl("/contact/", currentLanguage)}
        lottieData={ContactHoverLottieData}
        lottieStyles={{ offsetX: -40, offsetY: 10 }}
      >
        {t("contact")}
      </ContactLink>
    </>
  )
}

export default Header
