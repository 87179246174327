/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import Header from "./Header"
import SmoothScroll from "./SmoothScroll"
import Seo from "./seo"

import "./layout.css"

interface Props {
  showHeader?: boolean
}

const Layout: React.FC<Props> = ({ children, showHeader = true }) => {
  return (
    <>
      <Seo />
      <Header show={showHeader} />
      <main>
        <SmoothScroll>{children}</SmoothScroll>
      </main>
    </>
  )
}

export default Layout
