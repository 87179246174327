import React from "react"
import { useIsMobile } from "../../hooks/screen"
import DesktopHeader from "./DesktopHeader"
import MobileHeader from "./MobileHeader"

type Props = {
  show?: boolean
}

const Header = ({ show = true }: Props): JSX.Element => {
  const isMobile = useIsMobile()
  if (isMobile) {
    return <MobileHeader show={show} />
  }

  return <DesktopHeader show={show} />
}

export default Header
