import React, { useState, useCallback, useRef } from "react"

type ContextValues = {
  exiting: boolean
  entering: boolean
  transitionIn: () => void
  transitionOut: () => void
  timeElapsed: React.MutableRefObject<number | null>
}

const PageContext = React.createContext<ContextValues>({
  exiting: false,
  entering: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  transitionIn: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  transitionOut: () => {},
  timeElapsed: {
    current: null,
  },
})

export const PageTransitionContextProvider: React.FC = ({ children }) => {
  const [pageState, setPageState] = useState({
    exiting: false,
    entering: true,
  })
  const timeElapsed = useRef<number | null>(1500)
  const lastEvent = useRef<Date>(new Date())

  const transitionOut = useCallback(() => {
    const now = new Date()

    timeElapsed.current = Math.min(
      +now - +lastEvent.current,
      parseInt(process.env.PAGE_TRANSITION_DURATION_MS || "1500")
    )
    lastEvent.current = now

    setPageState({ exiting: true, entering: false })
  }, [])

  const transitionIn = useCallback(() => {
    timeElapsed.current = 1500
    lastEvent.current = new Date()
    setPageState({ exiting: false, entering: true })
  }, [])

  return (
    <PageContext.Provider
      value={{
        exiting: pageState.exiting,
        entering: pageState.entering,
        transitionIn,
        transitionOut,
        timeElapsed,
      }}
    >
      {children}
    </PageContext.Provider>
  )
}

export const usePageTransitionContext = () => React.useContext(PageContext)
