import React, { useEffect, useCallback, useRef } from "react"
import styled from "styled-components"
import lottie, { AnimationItem, AnimationSegment } from "lottie-web"
import { useLocation } from "@reach/router"
import { useTranslation } from "react-i18next"

import DelayLink from "../DelayLink"
import { getTranslatedUrl } from "../../utils/urls"

// Page lotties
import ScreenWritersLottieData from "../../../static/lotties/scenaristes.json"
import DirectorsLottieData from "../../../static/lotties/realisateurs.json"
import AboutLottieData from "../../../static/lotties/a-propos.json"
import PublishingLottieData from "../../../static/lotties/edition.json"
import ContactLottieData from "../../../static/lotties/contact.json"

const LogoContainer = styled.div`
  transform: translateX(25px);
  display: grid;
  grid-template-rows: 90px;
  grid-template-columns: 20px 1fr 50px;
  align-items: center;
  width: 250px;
  max-width: 100%;
`

const LogoImage = styled.img`
  grid-row: 1;
  grid-column: 1 / 3;
  margin-bottom: 0;
`

const LottieContainer = styled.div`
  grid-row: 1;
  grid-column: 1 / 4;
  height: 90px;
`

const LOTTIES: {
  [key: string]: { lottie: any; startFrames: AnimationSegment }
} = {
  "/fr/realisateurs/": {
    lottie: DirectorsLottieData,
    startFrames: [0, 110],
  },
  "/fr/scenaristes/": {
    lottie: ScreenWritersLottieData,
    startFrames: [0, 100],
  },
  "/fr/edition/": { lottie: PublishingLottieData, startFrames: [0, 120] },
  "/fr/a-propos/": { lottie: AboutLottieData, startFrames: [0, 80] },
  "/fr/contact/": { lottie: ContactLottieData, startFrames: [0, 80] },
  "/en/screenwriters/": {
    lottie: ScreenWritersLottieData,
    startFrames: [0, 100],
  },
  "/en/directors/": { lottie: DirectorsLottieData, startFrames: [0, 110] },
  "/en/publishing/": { lottie: PublishingLottieData, startFrames: [0, 120] },
  "/en/about/": { lottie: AboutLottieData, startFrames: [0, 80] },
  "/en/contact/": { lottie: ContactLottieData, startFrames: [0, 80] },
}

const EMPTY_LOTTIE: { lottie: any; startFrames: AnimationSegment } = {
  lottie: null,
  startFrames: [0, 100],
}

const Logo = (): JSX.Element => {
  const { i18n } = useTranslation()
  const animationQueued = useRef(false)
  const lottieRef = useRef<AnimationItem | null>(null)
  const lottieContainerRef = useRef<HTMLDivElement | null>(null)
  const { pathname } = useLocation()
  const currentAnimation = useRef(LOTTIES?.[pathname] ?? EMPTY_LOTTIE)

  const initAnimation = useCallback(() => {
    if (lottieContainerRef.current && currentAnimation.current?.lottie) {
      lottieRef.current = lottie.loadAnimation({
        container: lottieContainerRef.current,
        loop: false,
        autoplay: false,
        animationData: currentAnimation.current?.lottie,
        rendererSettings: {
          preserveAspectRatio: "xMaxYMax",
        },
      })

      lottieRef.current.addEventListener("complete", () => {
        animationQueued.current = false
      })
      lottieRef.current.setSpeed(3)

      if (lottieRef.current) {
        lottieRef.current.playSegments(
          currentAnimation.current?.startFrames || [0, 100],
          true
        )
      }
    }
  }, [])

  const onAnimationFinish = useCallback(() => {
    lottieRef.current?.destroy()
    initAnimation()
  }, [initAnimation])

  const finishAnimation = useCallback(() => {
    if (lottieRef.current) {
      animationQueued.current = true
      const currentFrame = lottieRef.current?.currentFrame
      lottieRef.current.addEventListener("complete", onAnimationFinish)
      lottieRef.current.resetSegments(true)
      lottieRef.current.goToAndPlay(currentFrame, true)
    }
  }, [onAnimationFinish])

  useEffect(() => {
    const runFinishAnimation = currentAnimation.current?.lottie
    if (pathname in LOTTIES) {
      currentAnimation.current = LOTTIES[pathname]
    } else {
      currentAnimation.current = EMPTY_LOTTIE
    }
    if (!animationQueued.current) {
      if (runFinishAnimation) {
        finishAnimation()
      } else {
        initAnimation()
      }
    }
  }, [pathname, initAnimation, finishAnimation])

  useEffect(() => {
    initAnimation()

    return () => {
      if (lottieRef.current) {
        lottieRef.current.destroy()
      }
    }
  }, [initAnimation])

  const currentLanguage = i18n.language as "fr" | "en"

  return (
    <DelayLink to={getTranslatedUrl("/featured/", currentLanguage)}>
      <LogoContainer>
        <LogoImage src="/images/logo.svg" alt="Film Talents Logo" />
        <LottieContainer ref={lottieContainerRef} />
      </LogoContainer>
    </DelayLink>
  )
}

export default Logo
