import React, { useRef, useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import gsap from "gsap/all"

import { getTranslatedUrl } from "../../utils/urls"

import DelayLink from "../DelayLink"
import Logo from "./Logo"

const HeaderWrapper = styled.header<{ show?: boolean }>`
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  position: fixed;
  z-index: 300;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity ease 300ms, background-color ease 300ms;
  background-color: transparent;
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? "all" : "none")};

  &.active {
    background-color: white;
  }
`

const Link = styled(DelayLink)<{ delay: number }>`
  text-transform: uppercase;
  font-family: "Avenir LT Std";
  font-size: 12pt;
  letter-spacing: 0.4em;
  margin-top: 10px;
  opacity: 0;
  transform: translateY(50px);
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 100;
`

const IGLink = styled.a`
  margin-top: 10px;
  height: 30px;
  opacity: 0;
  transform: translateY(50px);
`

const HamburgerIcon = styled.div<{ show?: boolean }>`
  display: block;
  position: fixed;
  top: 60px;
  right: 30px;
  z-index: 300;
  width: 20px;
  height: 15px;
  transform: rotate(0deg);
  cursor: pointer;
  transition: opacity ease 300ms;
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? "all" : "none")};

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: hsla(0, 0%, 0%, 0.8);
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  span:nth-child(1) {
    top: 0px;
    transform-origin: left center;
  }

  span:nth-child(2) {
    top: 6px;
    transform-origin: left center;
  }

  span:nth-child(3) {
    top: 12px;
    transform-origin: left center;
  }

  &.open span:nth-child(1) {
    transform: rotate(45deg);
    top: -1px;
    left: 4px;
  }

  &.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  &.open span:nth-child(3) {
    transform: rotate(-45deg);
    top: 13px;
    left: 4px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`

type Props = {
  show?: boolean
}

const MobileHeader = ({ show }: Props): JSX.Element => {
  const { t, i18n } = useTranslation()
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  const open = useRef(false)
  const hamburgerRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const onSmoothScroll = (e: any) => {
      if (e.detail.current.y > 100) {
        wrapperRef.current?.classList.add("active")
      } else {
        wrapperRef.current?.classList.remove("active")
      }
    }
    window.addEventListener("smoothscroll", onSmoothScroll)

    return () => {
      window.removeEventListener("smoothscroll", onSmoothScroll)
    }
  }, [])

  const onOpen = useCallback(() => {
    hamburgerRef.current?.classList.add("open")
    open.current = true

    if (containerRef.current) {
      gsap.to(containerRef.current, {
        x: "-100%",
        delay: 0,
        duration: 0.5,
        ease: "power1.inOut",
      })

      containerRef.current
        .querySelectorAll(".link")
        ?.forEach((element, index) => {
          gsap.set(element, {
            opacity: 0,
            y: 50,
            ease: "power3.inOut",
          })
          gsap.to(element, {
            opacity: 1,
            y: 0,
            duration: 0.3,
            delay: 0.3 + 0.1 * index,
            ease: "power3.inOut",
          })
        })
    }
  }, [])

  const onClose = useCallback(() => {
    hamburgerRef.current?.classList.remove("open")
    open.current = false
    if (containerRef.current) {
      gsap.to(containerRef.current, {
        x: 0,
        delay: 0.4,
        duration: 0.5,
        ease: "power1.inOut",
      })

      containerRef.current
        .querySelectorAll(".link")
        ?.forEach((element, index) => {
          gsap.to(element, {
            opacity: 0,
            y: -50,
            duration: 0.2,
            delay: 0.1 * index,
            ease: "power3.inOut",
          })
        })
    }
  }, [])

  const toggleMobileMenu = useCallback(() => {
    if (open.current) {
      onClose()
    } else {
      onOpen()
    }
  }, [onOpen, onClose])

  const currentLanguage = i18n.language as "fr" | "en"

  return (
    <>
      <HeaderWrapper show={show} onClick={onClose} ref={wrapperRef}>
        <Logo />
      </HeaderWrapper>

      <HeaderContainer ref={containerRef}>
        <Link
          onClick={onClose}
          className="link"
          to={getTranslatedUrl("/directors/", currentLanguage)}
          delay={100}
        >
          {t("directors")}
        </Link>
        <Link
          onClick={onClose}
          className="link"
          to={getTranslatedUrl("/screenwriters/", currentLanguage)}
          delay={0}
        >
          {t("screenwriters")}
        </Link>
        <Link
          onClick={onClose}
          className="link"
          to={getTranslatedUrl("/publishing/", currentLanguage)}
          delay={200}
        >
          {t("publishing")}
        </Link>
        <Link
          onClick={onClose}
          className="link"
          to={getTranslatedUrl("/about/", currentLanguage)}
          delay={300}
        >
          {t("about")}
        </Link>
        <Link
          onClick={onClose}
          className="link"
          delay={400}
          to={getTranslatedUrl("/contact/", currentLanguage)}
        >
          {t("contact")}
        </Link>
        <IGLink
          href="https://www.instagram.com/filmtalents/"
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          <img
            src="/images/instagram-icon.svg"
            width={25}
            height={25}
            alt="Film Talents - Instagram"
          />
        </IGLink>
      </HeaderContainer>
      <HamburgerIcon onClick={toggleMobileMenu} ref={hamburgerRef} show={show}>
        <span></span>
        <span></span>
        <span></span>
      </HamburgerIcon>
    </>
  )
}

export default MobileHeader
