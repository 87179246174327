import React, { useCallback, useRef, useEffect } from "react"
import { navigate, Link as LinkBase } from "gatsby"
import styled from "styled-components"
import { useLocation } from "@reach/router"
import lottie, { AnimationItem } from "lottie-web"
import { gsap } from "gsap"

import { usePageTransitionContext } from "../context/PageTransitionContext"

const LottieContainer = styled.div`
  position: absolute;
  top: 0;
  z-index: 10;
  will-change: transform;
  pointer-events: none;
`

const Link = styled(LinkBase)`
  position: relative;
`

const LinkWrapper = styled.div`
  position: relative;
`

type Props = React.HTMLAttributes<HTMLDivElement> & {
  to: string
  lottieData?: any
  lottieStyles?: {
    offsetX?: number
    offsetY?: number
    width?: number
    height?: number
  }
  onClick?: () => void
}

const DelayLink: React.FC<Props> = ({
  children,
  to,
  lottieData,
  lottieStyles,
  onClick,
  ...props
}) => {
  const { pathname } = useLocation()
  const {
    transitionIn,
    transitionOut,
    timeElapsed,
  } = usePageTransitionContext()
  const timeoutRef = useRef(0)
  const animationElement = useRef<HTMLDivElement | null>(null)
  const lottieRef = useRef<AnimationItem | null>(null)
  const linkRef = useRef<any | null>(null)
  const mouseMoveFrameId = useRef(0)

  const handleClick = useCallback(
    e => {
      e.preventDefault()
      if (onClick) {
        onClick()
      }
      window.clearTimeout(timeoutRef.current)
      transitionOut()

      timeoutRef.current = window.setTimeout(() => {
        navigate(to)
        // TODO: remove timeout and optimise load time using gatsby image
        window.setTimeout(() => {
          /**
           * Timeout to fix first load of pages taking long.
           * This causes an effect where previous page slides open briefly before
           * new page loads. Hoping this is fixed when we implement gatsby image
           */
          transitionIn()
        }, 100)
      }, timeElapsed?.current ?? 1500)
    },
    [to, transitionOut, transitionIn, onClick]
  )

  useEffect(() => {
    const currentLinkRef = linkRef.current

    const onMoveEnter = () => {
      lottieRef.current?.setDirection(1)
      lottieRef.current?.play()
    }

    const onMouseMove = (e: MouseEvent) => {
      const {
        height,
        width,
      } = (e.target as HTMLElement).getBoundingClientRect()
      const offsetX = (e?.offsetX - width / 2) * 0.5
      const offsetY = (e?.offsetY - height / 2) * 0.5

      gsap.to(animationElement.current, {
        x: offsetX,
        y: offsetY,
        ease: "linear.inOut",
        duration: 1,
      })
    }

    const onMouseLeave = () => {
      lottieRef.current?.setDirection(-1)
      lottieRef.current?.play()
      gsap.to(animationElement.current, {
        x: 0,
        y: 0,
        ease: "power3.inOut",
        duration: 1,
      })
    }

    if (animationElement.current && lottieData && !lottieRef.current) {
      lottieRef.current = lottie.loadAnimation({
        container: animationElement.current,
        loop: false,
        autoplay: false,
        animationData: lottieData,
        rendererSettings: {
          preserveAspectRatio: "xMaxYMax",
        },
      })

      lottieRef.current.setSpeed(1)
    }

    if (currentLinkRef && lottieData) {
      currentLinkRef.addEventListener("mousemove", onMouseMove)
      currentLinkRef.addEventListener("mouseleave", onMouseLeave)
      currentLinkRef.addEventListener("mouseenter", onMoveEnter)
    }

    return () => {
      if (currentLinkRef && lottieData) {
        currentLinkRef.removeEventListener("mousemove", onMouseMove)
        currentLinkRef.removeEventListener("mouseLeav", onMouseLeave)
        currentLinkRef.removeEventListener("mouseenter", onMoveEnter)
      }
      window.clearTimeout(mouseMoveFrameId.current)
    }
  }, [])

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  return (
    <LinkWrapper {...props}>
      <Link
        ref={linkRef}
        to={to}
        onClick={pathname !== to ? handleClick : undefined}
        style={{ cursor: "pointer", ...props.style }}
      >
        {children}
      </Link>
      {lottieData ? (
        <LottieContainer
          style={{
            width: lottieStyles?.width ?? "100%",
            height: lottieStyles?.height ?? "100%",
            top: lottieStyles?.offsetY ?? 0,
            left: lottieStyles?.offsetX ?? 0,
          }}
          className="lottie-container"
          ref={animationElement}
        />
      ) : null}
    </LinkWrapper>
  )
}

export default DelayLink
