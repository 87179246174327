import React from "react"
import { I18nextProvider } from "react-i18next"
import { PageTransitionContextProvider } from "./context/PageTransitionContext"
import i18n from "./i18n"

const wrapRootElement = ({
  element,
}: {
  element: React.ReactNode
}): React.ReactNode => {
  return (
    <I18nextProvider i18n={i18n}>
      <PageTransitionContextProvider>{element}</PageTransitionContextProvider>
    </I18nextProvider>
  )
}

export default wrapRootElement
