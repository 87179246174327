import React, { useRef, useEffect, useCallback } from "react"
import styled from "styled-components"
import { gsap } from "gsap"
import { usePageTransitionContext } from "../context/PageTransitionContext"

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`

const Content = styled.div``

const SmoothScroll: React.FC = ({ children, ...props }) => {
  const scrollEl = useRef<HTMLDivElement | null>(null)
  const scrollContentEl = useRef<HTMLDivElement | null>(null)
  const frameId = useRef(0)
  const scroller = useRef({
    target: scrollContentEl,
    ease: 0.1,
    endY: 0,
    y: 0,
    resizeRequest: 1,
    scrollRequest: 0,
  })
  const { entering } = usePageTransitionContext()
  const customEvent = useRef<any>(null)

  const initBodyHeight = useCallback(() => {
    if (document.body && scrollContentEl.current) {
      document.body.style.height = `${scrollContentEl.current.offsetHeight}px`
    }
  }, [])

  const onResize = useCallback(() => {
    initBodyHeight()
  }, [initBodyHeight])

  const updateScroller = useCallback(() => {
    const resized = scroller.current.resizeRequest > 0

    if (resized) {
      initBodyHeight()
      scroller.current.resizeRequest = 0
    }

    const scrollY = window.pageYOffset

    scroller.current.endY = scrollY
    scroller.current.y += (scrollY - scroller.current.y) * scroller.current.ease

    if (Math.abs(scrollY - scroller.current.y) < 0.05) {
      scroller.current.y = scrollY
      scroller.current.scrollRequest = 0
    }

    gsap.set(scroller.current.target.current, {
      y: -scroller.current.y,
    })

    if (customEvent.current) {
      window.dispatchEvent(customEvent.current)
    }

    frameId.current =
      scroller.current.scrollRequest > 0
        ? window.requestAnimationFrame(updateScroller)
        : 0
  }, [initBodyHeight])

  const onScroll = useCallback(() => {
    if (scroller.current) {
      scroller.current.scrollRequest++
    }
    if (!frameId.current) {
      // start scrolling
      frameId.current = window.requestAnimationFrame(updateScroller)
    }
  }, [updateScroller])

  useEffect(() => {
    if (!customEvent.current) {
      customEvent.current = new CustomEvent("smoothscroll", {
        detail: scroller,
      })
    }

    window.addEventListener("scroll", onScroll, { passive: true })
    window.addEventListener("resize", onResize, { passive: true })

    return () => {
      window.removeEventListener("scroll", onScroll)
      window.removeEventListener("resize", onResize)
      window.cancelAnimationFrame(frameId.current)
    }
  }, [initBodyHeight, onScroll, onResize])

  useEffect(() => {
    initBodyHeight()
  }, [entering, initBodyHeight])

  return (
    <Container ref={scrollEl} {...props}>
      <Content ref={scrollContentEl}>{children}</Content>
    </Container>
  )
}

export default SmoothScroll
