import React from "react"

import Layout from "./components/Layout"
import { LanguageContextProvider } from "./context/LanguageContext"

const wrapPageElement = ({
  element,
  props,
}: {
  element: React.ReactNode
  props: {
    location: {
      pathname: string
    }
    pageContext: {
      lang: string
    }
  }
}): React.ReactNode => {
  return (
    <LanguageContextProvider lang={props.pageContext.lang}>
      <Layout showHeader={props.location.pathname !== "/"}>{element}</Layout>
    </LanguageContextProvider>
  )
}

export default wrapPageElement
